/* Shared Component Styles */
.component-card {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: 0.3s;
  }
  
  .component-card:hover {
    transform: scale(1.02);
  }
  
  /* Checkbox Label */
  .checkbox-label {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  /* Form Container */
  .form-container {
    background: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  /* Form Title */
  .form-title {
    font-size: 16px;
    font-weight: bold;
    color: #495057;
    margin-bottom: 10px;
  }
  
  /* Form Inputs */
  .form-input {
    width: 90%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .workorder-inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  /* File Upload */
  .file-input {
    margin-top: 10px;
  }
  
  /* Buttons */
  .fill-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .fill-btn:hover {
    background-color: #0056b3;
  }
  
  /* Save Button */
  .save-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .save-btn:hover {
    background-color: #218838;
  }




  /* Checkbox Styling */
.checkbox-label {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  /* Hide default checkbox */
  .checkbox-label input[type="checkbox"] {
    display: none;
  }
  
  /* Custom Checkbox */
  .checkbox-label input[type="checkbox"] + span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    transition: 0.3s;
  }
  
  /* Checked Checkbox - Green with Tick */
  .checkbox-label input[type="checkbox"]:checked + span {
    background-color: #28a745;
    color: white;
    content: "✔";
  }
  
  /* Unchecked Checkbox - Red with Cross */
  .checkbox-label input[type="checkbox"]:not(:checked) + span {
    background-color: #dc3545;
    color: white;
    content: "✖";
  }
  
  