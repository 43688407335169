/* Container */
.nsc-container {
    width: 70%;
    /* min-width: 450px; */
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .total-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  /* Title */
  .title {
    font-size: 26px;
    text-align: center;
    color: #343a40;
    font-weight: bold;
  }
  
  /* Card Layout */
  .billing-card {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Grid Layout */
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-top: 10px;
  }
  
  /* Buttons */
  .button-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }
  
  .save-btn, .reset-btn, .generate-btn, .download-btn {
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .save-btn {
    background-color: #28a745;
    color: white;
  }
  
  .reset-btn {
    background-color: #dc3545;
    color: white;
  }
  
  .generate-btn {
    background-color: #007bff;
    color: white;
  }
  
  .download-btn {
    background-color: #17a2b8;
    color: white;
  }
  
  .save-btn:hover {
    background-color: #218838;
  }
  
  .reset-btn:hover {
    background-color: #c82333;
  }
  
  .generate-btn:hover {
    background-color: #0056b3;
  }
  
  .download-btn:hover {
    background-color: #138496;
  }
  
  /* PDF Preview */
  .pdf-preview {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    background: white;
    border-radius: 8px;
    text-align: center;
  }
  